import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import styled from "styled-components"
import Header from "./header"
import MailChimp from "./mailchimp-signup"
import Menu from "../../pages/menu-page"
import facebookw from "../../assets/facebook-w.svg"
import favicon from "../../assets/favicon-bs.ico"
import menuIcon from "../../assets/Hamburger_icon.svg"
import instagramw from "../../assets/instagram-w.svg"
import "../../styles/layout.css"
import styles from "../../styles/layout.module.css"

const HMDiv = styled.div`
  display: grid;
  align-items: center;
  background: white;
  width: 100%;
  height: 75px;
  grid-template-columns: 15fr 1fr 1fr 0.2fr;
  grid-column-gap: 0.1rem;
  grid-template-rows: 2fr 1fr;
  color: #19232d;
  font-weight: bold;
  position: fixed;
  z-index: 90;
  top: 0;
`

const HeaderDiv = styled(Header)`
  grid-row: 1 / 1;
  grid-column-start: 1;
  grid-column-end: 3;
  align-self: center;
  font-weight: bold;
  &:hover {
    cursor: pointer;
  }
`

const MenuDiv = styled.div`
  grid-column: 3 / 3;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-self: center;
  justify-self: start;
  color: #19232d;
  font-weight: bold;
  &:hover {
    cursor: pointer;
  }
`

const MenuWord = styled.div`
  grid-column: 1 / span 1;
  justify-self: end;
  font-family: "Overpass SemiBold";
  margin-right: 10px;
`

const MenuIcon = styled.img`
  grid-column: 2 / span 1;
  justify-self: start;
  height: 20px;
`

const RootLine = styled.hr`
  width: 100%;
  height: 1px;
  margin-top: 0;
  background-color: #232d36;
  border: none;
  opacity: 0.42;
  position: absolute;
`

const ShowMenuContainer = styled.div`
  display: grid;
  align-items: center;
  height: 75px;
  color: #19232d;
  font-weight: bold;
  position: fixed;
  top: 0;
  left: 0;
  background: "red";
  z-index: 100;
`

class Layout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showMenu: false
    }
  }

  handleMenuOpen = () => this.setState({ showMenu: true })

  handleMenuClose = ({ showMenu }) => this.setState({ showMenu })

  render() {
    const { showMenu } = this.state
    const { children } = this.props
    const layout = (
      <>
        <Helmet>
          <link rel="icon" type="image/x-icon" sizes="16x16 32x32 64x64 256x256" href={favicon} />
          <script type="text/javascript" src="//cdn.iubenda.com/cs/tcf/stub.js" async />
          <script type="text/javascript">
            {`var _iub = _iub || [];
      _iub.csConfiguration = {"cookiePolicyInOtherWindow":true,"enableRemoteConsent":true,"enableCMP":true,"askConsentIfCMPNotFound":false,"gdprAppliesGlobally":true,"lang":"de","siteId":1596961,"consentOnScroll":false,"cookiePolicyId":39071544, "banner":{ "prependOnBody":false,"position":"bottom","textColor":"#dadada","backgroundColor":"#5A5A5A" }};`}
          </script>
          <script
            type="text/javascript"
            src="//cdn.iubenda.com/cs/iubenda_cs.js"
            charset="UTF-8"
            async
          />
        </Helmet>
        <HMDiv>
          <HeaderDiv />
          <MenuDiv>
            <MenuWord id="menuOpen" onClick={this.handleMenuOpen}>
              MENÜ
            </MenuWord>
            <MenuIcon id="menuOpen" src={menuIcon} alt="menu" onClick={this.handleMenuOpen} />
          </MenuDiv>
        </HMDiv>
        <RootLine />
        {showMenu && (
          <ShowMenuContainer>
            <Menu className={styles.displayMenu} onClick={this.handleMenuClose} />
          </ShowMenuContainer>
        )}
        <div className={styles.content}>{children}</div>
        <footer className={styles.footer}>
          <div className={styles.footerWrapper}>
            <div className={styles.newsletter}>
              <p className={styles.title}>Never miss a thing</p>
              <MailChimp />
            </div>
            <div className={styles.col2}>
              <Link to="/about">Über uns</Link>
              <br />
              <Link to="/impressum">Impressum</Link>
            </div>
            <div className={styles.col3}>
              {/* <Link>FAQ</Link>
          <br />
          <Link>Produkte</Link> */}
            </div>

            <div className={styles.col4}>
              <a href="https://www.iubenda.com/privacy-policy/39071544">Datenschutzerklärung</a>
              <br />
              <a href="https://www.iubenda.com/privacy-policy/39071544/cookie-policy">
                Cookierichtlinie
              </a>
            </div>

            <div className={styles.social}>
              <div className={styles.textwrapper}>
                <p>Wir sind social</p>
              </div>
              <div className={styles.socialcontainer}>
                <a className={styles.sociallogo} href="https://www.facebook.com/besserschlafen.de">
                  <img src={facebookw} alt="facebook-logo" />
                </a>
              </div>
              <div className={styles.socialcontainer}>
                <a
                  className={styles.sociallogo}
                  href="https://www.instagram.com/besserschlafen.de/"
                >
                  <img src={instagramw} alt="instagram-logo" />
                </a>
              </div>
            </div>
          </div>
        </footer>
      </>
    )
    return <div className={showMenu ? styles.menuDisplay : ""}>{layout}</div>
  }
}
export default Layout
