/* eslint-disable no-console */
import React from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import styles from "../../styles/mailchimp-signup.module.css"

export default class MailChimp extends React.Component {
  state = {
    name: null,
    email: null
  }

  _handleChange = e => {
    console.log({
      [`${e.target.name}`]: e.target.value
    })
    this.setState({
      [`${e.target.name}`]: e.target.value
    })
  }

  _handleSubmit = e => {
    e.preventDefault()
    addToMailchimp(this.state.email, this.state)
      .then(({ msg, result }) => {
        if (result !== "success") throw msg
        alert(msg)
      })
      .catch(err => alert(err))
  }

  render() {
    return (
      <div>
        <div className={styles.mailchimp}>
          <form onSubmit={this._handleSubmit}>
            <input
              className={styles.mcinput}
              type="email"
              onChange={this._handleChange}
              placeholder="Email Adresse hier eingeben"
              name="email"
              autoComplete="email"
            />
            <br />
            <button className={styles.submit} type="submit">
              SUBSCRIBE
            </button>
          </form>
        </div>
      </div>
    )
  }
}
