import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import bslogob from "../../assets/bs-logo-b.svg"

const HeaderRoot = styled.div`
  width: 100%;
  justify-self: center;
  align-self: center;
  background-color: #ffffff;
  display: grid;
  grid-template-columns: 30px 1fr 2fr 1fr;
  align-items: center;
`

const HeaderLink = styled(Link)`
  grid-column: 2 / span 1;
  cursor: pointer;
`

const DefaultPageLogo = styled.img`
  height: 60px;
  padding-top: 6px;
`

const imgstyle = {
  height: "60px"
}

const handleClickOnHeader = () => {}

const Header = () => {
  return (
    <HeaderRoot onClick={handleClickOnHeader}>
      <HeaderLink to="/">
        <DefaultPageLogo style={imgstyle} src={bslogob} alt="bsb-logo" />
      </HeaderLink>
    </HeaderRoot>
  )
}

export default Header
